$(function() {
   var player_color = "w";
   window.level = 1;
   $(".play").on("click", function (e) {
      $('.start-btn').hide();
      $("#gameModal").modal('show');
      $(".nav-close.active p").click();
      window.level = $(this).data("it");
	});
   $(".play-0").on("click", function (e) {
      $('.start-btn').hide();
      $("#gameModal").modal('hide');
      $(".nav-close.active p").click();
      puzzleEditorViewModel.timeouts = [];
      puzzleEditorViewModel.board.onreset();
      puzzleEditorViewModel.board.pturn(false); 
      puzzleEditorViewModel.board.pmturn(false); 
      puzzleEditorViewModel.board.pcturn(false); 
      puzzleEditorViewModel.board.updateBoard();
      puzzleEditorViewModel.board.palings.removeAll();

      puzzleEditorViewModel.board.disabled(false); 
      window.puzzle = {category: 0, player_color: player_color, flip: player_color == "w" ? false : true, pgn: '[FEN "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1"]\n\n*', number: 0, description: "Game vs bot", it: 0};
      window.puzzle.solution = '';
      puzzleEditorViewModel.load(window.puzzle); 
      puzzleEditorViewModel.board.flip(player_color === "b");

      if (player_color === 'b') {
         var moves_p = puzzleEditorViewModel.board.chess.moves();
         var premier_num = Math.floor(Math.random(moves_p.length) * moves_p.length);
         window.setTimeout(premierMome(moves_p[premier_num]), 1500);
         //var moveObject = puzzleEditorViewModel.board.makeMove(moves_p[premier_num], true);
         //window.setTimeout(makeComputerMove, 250); //Computer makes the first move
       }
       function premierMome (move){
         var moveObject1 = puzzleEditorViewModel.board.makeMove(move, true);

       }

	});
   $(".play-1").on("click", function (e) {
      $('.start-btn').hide();
      $("#gameModal").modal('hide');
      $(".nav-close.active p").click();
	});
   $(".cancel").on("click", function (e) {
      $('.start-btn').show();
      $("#gameModal").modal('hide');
	});

   $('.choose-color').change(function(){
      console.log($("input[name='choose-color']:checked").val());
		player_color = $("input[name='choose-color']:checked").val();
	});
/*

*/
});




(function() {
var getBestMove = (async function (fen, depth) {
   try {
     const response = await fetch(`https://stockfish.online/api/s/v2.php?fen=${fen}&depth=${depth}`);
     const data = await response.json();
     if (data.success) {
       const bestMove = data.bestmove.split(' ')[1]; //Extract the move part
       console.log('Best move:', JSON.stringify(data));
       console.log('Best move:', bestMove);
       return bestMove;
     } else {
       console.error('Error getting best move:', data);
       return null;
     }
   } catch (error) {
     console.error('Error fetching best move:', error);
     return null;
   }
});
window.getBestMove = getBestMove;
}).call(this);
(function() {
   var makeComputerMove = (async function() {
      const fen = puzzleEditorViewModel.board.chess.fen();
      const bestMove = await getBestMove(fen, window.level);// * 3); //Adjust depth based on level
      if (bestMove) {
         var moveObject = puzzleEditorViewModel.board.makeMove({from: bestMove.substring(0, 2), to: bestMove.substring(2, 4), promotion: bestMove.length > 4 ? bestMove[4] : 'q' }, true);
         if (moveObject) {
            if (puzzleEditorViewModel.board.chess.game_over()) {
               window.setTimeout(alert('Game over'), 250); 
               //alert('Game over');
            }
         } else {
            console.error('Invalid move:', bestMove);
         }
      }
   });
   window.makeComputerMove = makeComputerMove;
}).call(this);


(function() {
   
  var wpuzzle = (function(puzzle, sign, ipuz, it, help) {
    var c_t_1,c_t_2;
    var it = it;
    var pn = (puzzle != window.puzzle.id && !help);
    var pn1 = window.puzzle.description;
    $(".start-btn").hide();
    if ($(window).width() > 991) {
      if (st002 && st002.classList.contains("active")) {
        st002.classList.remove("active");
        }  
      if (st002 && st003.classList.contains("active")) {
        st003.classList.remove("active");
        }  
      }
    puzzleEditorViewModel.timeouts = [];
    puzzleEditorViewModel.board.onreset();
    puzzleEditorViewModel.board.pturn(false); 
    puzzleEditorViewModel.board.pmturn(false); 
    puzzleEditorViewModel.board.pcturn(false); 
    puzzleEditorViewModel.board.updateBoard();
    puzzleEditorViewModel.board.palings.removeAll();
    if (resourceUserB == "puzzle" || resourceUserB == "chapter") {
        puzzleEditorViewModel.displayPgn(false);
        puzzleEditorViewModel.displayMessage(true);
     }

    if (sign || ipuz < 2) {
      puzzleEditorViewModel.board.disabled(false);            
      $.ajax({
        url:"/puzzles/solver?puzzle="+puzzle,
        type:'GET',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        async:false,
        dataType:'json',
        success:function(data){
                window.puzzle = data;
                window.puzzle.solution = '';
                puzzleEditorViewModel.load(window.puzzle); 
                return window.puzzle;                    
                }});
                if (resourceUserB == "puzzle" || resourceUserB == "chapter") {
                   if (window.puzzle.solved || window.puzzle.err || puzzleEditorViewModel.board.pgn().body.moves.length <3 || 
                      (window.puzzle.hint + window.puzzle.mistake)>= Math.round((puzzleEditorViewModel.board.pgn().body.moves.length-1)/5) || 
                      window.puzzle.userRating > 2300 || (window.puzzle.hint + window.puzzle.mistake) > 3) {
                        puzzleEditorViewModel.hintButton(true);
                   }else {
                     puzzleEditorViewModel.hintButton(false);
                   } 
                   } 
        $('.board-inner .pieces').removeClass('goblin-1'); 
        $('.board-inner .pieces').removeClass('goblin-2');
        $('.board-inner .pieces').removeClass('goblin-3');  
        var goblinNum = Math.floor(Math.random() * 3 ) + 1;  
        if (goblinNum == 1) {
        	 $('.board-inner .pieces').addClass('goblin-1'); 
        }else if (goblinNum == 2) {
        	 $('.board-inner .pieces').addClass('goblin-2'); 
        }else {
        	 $('.board-inner .pieces').addClass('goblin-3');
        }  
        $(".nav-close.active p").click();                 
    }else {
      location.href="users/sign_in";
      }
 
    $('.tasks-content ul li').removeClass('active');
    $('.nav-theme ul li').removeClass('active');    
    $("#puzzle-"+puzzle).addClass("active");   
    $("#theme-"+it).addClass("active");   
$('.task-help').html(window.puzzle.description);
//$('.task-help').html(help);
    puzzleEditorViewModel.board.movelast.removeAll();
        if (resourceUserB == "puzzle" || resourceUserB == "chapter") {
delete window.keyboardController.bind();
          }
//     alert(help + '----' + pn1 + '----' + window.puzzle.description);
     if ((pn && window.puzzle.number == 1)||(pn1 != window.puzzle.description)) {
     	 $("#taskModal2").modal('show');
     }        
  }); 
 window.wpuzzle = wpuzzle;
}).call(this);
(function() {
  var wsolve = (function(puzzle){ 
  if (resourceUserB == "puzzle" || resourceUserB == "chapter") {
        puzzleEditorViewModel.displayPgn(true);
        puzzleEditorViewModel.displayMessage(false);
       window.keyboardController.bind("PgnNavHandler", puzzleEditorViewModel.board);
  }  
    if (!puzzle.solved && !puzzle.err) {
         $.ajax({
          url: "/puzzles/mistake",
          type: 'POST', 
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          data: ({id: window.puzzle.id}), 
          dataType: "json",
          success: function() {
          }                  
        });
      }
    puzzleEditorViewModel.board.disabled(true);        

    cubeAnimateTwo();
 
    $("#taskModal1").modal('show');
if (sound) {document.getElementById("m010").play(); }         
    setTimeout(function() {
     wpuzzle(window.puzzle.id, sign1, window.puzzle.ipuz);
     }, 1000);
      
   });
   window.wsolve = wsolve;
   }).call(this);

(function() {
   var solved = (function(puzzle){ 
      var ls = [];  
      var nRating, koefRating, dR, cR, league;
      league = puzzle.league;
      var lc = ['#ffffff','#fc8a3a','#f9ede3','#f8e83d','#2554b2','#db3d4d','#4ea814','#8a509f','#ffffff','#4cf8f2','#deeffe','#ffffff']
      if (window.puzzle.locale == 'ru') {
         ls = ["", "Бронзовая", "Серебряная", "Золотая", "Сапфировая", "Рубиновая", "Изумрудная", "Аметистовая", "Жемчужная", "Бирюзовая", "Бриллиантовая"];
      }else if (window.puzzle.locale == 'uk') {
         ls = ["", "Бронзова", "Срібна", "Золота", "Сапфірова", "Рубінова", "Смарагдова", "Аметистова", "Перлинна", "Бірюзова", "Діамантова"];
      }else {
         ls = ["", "Bronze", "Silver", "Gold", "Sapphire", "Ruby", "Emerald", "Amethyst", "Pearl", "Turquoise", "Diamond"];
      }
      if (resourceUserB == "puzzle" || resourceUserB == "chapter") {    
      window.keyboardController.bind("PgnNavHandler", puzzleEditorViewModel.board);
      }

      if (!puzzle.solved && !puzzle.err) {
        /* console.log(JSON.stringify(puzzle));*/
         if (puzzle.mistake === 0) {
            dR = 4;
         }else if (puzzle.mistake === 1) {
            dR = 2;
         }else {
            dR = 1;
         };
         nRating = puzzle.userRating + dR;
         $('.achievement .number').html(nRating);
         if (puzzle.league === 0 && nRating > 1) {
            $(".player-liga, .player-next").removeClass("invisible");
         }
         if (puzzle.league === 9 && nRating > 2049) {
            $(".player-next").addClass("invisible");
            puzzle.league = 10;
            $('.player-liga span').html(ls[10]);
            $("#grattersModal").modal('show');
         }
         if ((puzzle.league === 0 && nRating > 1) || 
            (puzzle.league < 2 && nRating > 149) || 
            (puzzle.league < 3 && nRating > 349) || 
            (puzzle.league < 4 && nRating > 549) || 
            (puzzle.league < 5 && nRating > 799) || 
            (puzzle.league < 6 && nRating > 1049) || 
            (puzzle.league < 7 && nRating > 1299) || 
            (puzzle.league < 8 && nRating > 1549) || 
            (puzzle.league < 9 && nRating > 1799)) {
               puzzle.league += 1;
               $('.player-liga span').css("color", lc[puzzle.league]);
               $('.player-liga span').html(ls[puzzle.league]);
               $('.player-next span').css("color", lc[puzzle.league + 1]);
               $('.player-next span').html(ls[puzzle.league + 1]);
               $("#grattersModal").modal('show');
if (sound) {document.getElementById("m011").play(); } 
          }
 
      $.ajax({
         url: "/puzzles/solved",
         type: 'POST', 
         beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
         data: ({id: puzzle.id, n_rating: nRating, dr: dR, league: puzzle.league}), 
         dataType: "json",
         success: function() {
         }                  
      });            
puzzleEditorViewModel.board.disabled(true);        
      window.puzzle.solved = true;
      window.puzzle.err=false;
$('.puzzl'+puzzle.id).addClass("task_done");
      $('#sol').html(+dR);

      };

cubeAnimateOne();

$("#taskModal").modal('show');

/*console.log(window.puzzle.next_p + ' ------ ' + sign1)*/
setTimeout(function() {
   $('#taskModal .close-btn').click();
   wpuzzle(window.puzzle.next_p, sign1, window.puzzle.ipuz, window.puzzle.it);
   }, 1500);      
      
          if (resourceUserB == "puzzle" || resourceUserB == "chapter") {
            puzzleEditorViewModel.displayPgn(true);
         puzzleEditorViewModel.displayMessage(false);
       }  
        
      });
   window.solved = solved;
   }).call(this);

 (function() {
  var mistake = (function(){   
         $.ajax({
          url: "/puzzles/mistake",
          type: 'POST', 
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          data: ({id: window.puzzle.id}), 
          dataType: "json",
          success: function() {
             }                  
      }); 
  
    $("#taskModal1").modal('show');
if (sound) {document.getElementById("m010").play(); }     
    setTimeout(function() {
     $('#taskModal1 .close-btn').click();
     }, 1500);  
      
      });
   window.mistake = mistake;
   }).call(this);
