(function() {
  var _ref,
    __hasProp = {}.hasOwnProperty,
    __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

  if ((_ref = window.PGN) == null) {
    window.PGN = {};
  }

  PGN.Game = (function() {

    Game.DEFAULT_FEN = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";

    function Game(data) {
      if (data == null) {
        data = null;
      }
      this.nextId = -1;
      this.header = (data != null ? data.header : void 0) || {};
      this.terminator = data != null ? data.terminator : void 0;
      this.initialFen = this.header["FEN"] || PGN.Game.DEFAULT_FEN;
      this.nodes = {};
      this.body = this.createNode("nodelist", {
        moves: data != null ? data.moves : void 0,
        fen: this.initialFen
      });
    }

    Game.prototype.createNode = function(type, data) {
      var kls;
      if (data == null) {
        data = null;
      }
      kls = (function() {
        switch (type) {
          case "move":
            return PGN.Move;
          case "commentary":
            return PGN.Commentary;
          case "nodelist":
            return PGN.NodeList;
          case "nag":
            return PGN.NAG;
        }
      })();
      return new kls(this, data);
    };

    Game.prototype.getNextId = function() {
      this.nextId += 1;
      return this.nextId;
    };

    Game.prototype.find = function(moveNumber, variantSerial) {
      var move, nodeList, _i, _len, _ref1;
      nodeList = this.body.findRavByRelativeSerial(variantSerial || 0);
      if (nodeList) {
        _ref1 = nodeList.moves;
        for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
          move = _ref1[_i];
          if (moveNumber === move.number) {
            return move;
          }
        }
      }
    };

    Game.prototype.findByIdent = function(ident) {
      var int, moveNumber, variantSerial, _ref1;
      _ref1 = (function() {
        var _i, _len, _ref1, _results;
        _ref1 = ident.split("-");
        _results = [];
        for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
          int = _ref1[_i];
          _results.push(Number(int));
        }
        return _results;
      })(), moveNumber = _ref1[0], variantSerial = _ref1[1];
      console.log([moveNumber, variantSerial]);
      return this.find(moveNumber, variantSerial);
    };

    Game.prototype.insertMove = function(san, from, branchMethod) {
      var fromFen, moveIndex, moveObject, nextMoveIndex, nextMoveNodeIndex, node, nodeList, rav, ravs, realTail, tail, _i, _j, _k, _len, _len1, _len2, _ref1;
      if (from == null) {
        from = null;
      }
      if (branchMethod == null) {
        branchMethod = null;
      }
      if (from) {
        nodeList = from.parent;
        moveIndex = nodeList.moves.indexOf(from);
        fromFen = from.fen;
      } else {
        nodeList = this.body;
        moveIndex = -1;
        fromFen = this.body.fen;
      }
      if (moveIndex === nodeList.moves.length - 1) {
        return nodeList.appendNode({
          type: "move",
          san: san
        });
      }
      branchMethod || (branchMethod = 'new');
      nextMoveNodeIndex = nodeList.nodes.indexOf(nodeList.moves[moveIndex + 1]);
      while (nextMoveNodeIndex + 1 < nodeList.nodes.length && !nodeList.nodes[nextMoveNodeIndex + 1].isMove) {
        nextMoveNodeIndex++;
      }
      if (branchMethod === 'new') {
        rav = this.createNode("nodelist", {
          fen: fromFen,
          defaultFirstMoveNumber: nodeList.moves[moveIndex + 1].number
        });
        nodeList.insertNode(rav, nextMoveNodeIndex + 1);
        return rav.appendNode({
          type: "move",
          san: san
        });
      }
      nextMoveIndex = moveIndex + 1;
      tail = nodeList.removeNode(nodeList.moves[nextMoveIndex]);
      realTail = [];
      ravs = [];
      for (_i = 0, _len = tail.length; _i < _len; _i++) {
        node = tail[_i];
        if (node.isNodeList && ((_ref1 = node.moves[0]) != null ? _ref1.number : void 0) === moveIndex + 1) {
          ravs.push(node);
        } else {
          realTail.push(node);
        }
      }
      moveObject = nodeList.appendNode({
        type: "move",
        san: san
      });
      if (realTail.length > 0 && branchMethod === 'new_main') {
        rav = this.createNode("nodelist", {
          fen: fromFen
        });
        for (_j = 0, _len1 = realTail.length; _j < _len1; _j++) {
          node = realTail[_j];
          rav.appendNode(node);
        }
        nodeList.appendNode(rav);
      }
      for (_k = 0, _len2 = ravs.length; _k < _len2; _k++) {
        node = ravs[_k];
        nodeList.appendNode(node);
      }
      return moveObject;
    };

    Game.prototype.nextMovesFrom = function(move) {
      var nextMove, result, variants;
      if (move && move.parent) {
        nextMove = move.nextMove();
        variants = move.parent.nextVariantMovesStarting(move.number + 1);
      } else {
        nextMove = this.body.moves[0];
        variants = this.body.nextVariantMovesStarting(0);
      }
      result = [];
      if (nextMove) {
        result.push(nextMove);
      }
      result = result.concat(variants);
      return result;
    };

    Game.prototype.toString = function() {
      var bd, result, tagName, tagValue, _ref1;
      result = "";
      _ref1 = this.header;
      for (tagName in _ref1) {
        tagValue = _ref1[tagName];
        result += "[" + tagName + " \"" + tagValue.replace(/(["\\])/g, "\\$1") + "\"]\n";
      }
      if (result.length > 0) {
        result += "\n";
      }
      bd = this.body.toString();
      result += bd;
      if (bd.length > 0) {
        result += " ";
      }
      result += this.terminator || "*";
      return result;
    };

    return Game;

  })();

  PGN.Node = (function() {

    Node.prototype.isNode = true;

    function Node(game, data) {
      if (data == null) {
        data = null;
      }
      this.game = game;
      this.id = this.game.getNextId();
      if (data != null) {
        this.update(data);
      }
      this.game.nodes[this.id] = this;
    }

    Node.prototype.prev = function() {
      var index;
      if (!this.parent) {
        return null;
      }
      index = this.parent.nodes.indexOf(this);
      if (index === -1) {
        return null;
      }
      if (index > 0) {
        return this.parent.nodes[index - 1];
      } else {
        return this.parent.prev();
      }
    };

    Node.prototype.nextMove = function() {
      var index;
      if (!this.parent) {
        return null;
      }
      index = this.parent.moves.indexOf(this);
      if (index === -1) {
        return null;
      }
      if (this.parent.moves.length > index + 1) {
        return this.parent.moves[index + 1];
      } else {
        return null;
      }
    };

    Node.prototype.prevMove = function() {
      var index, moveNumber;
      if (!this.parent) {
        return null;
      }
      index = this.parent.moves.indexOf(this);
      if (index > 0) {
        return this.parent.moves[index - 1];
      } else {
        moveNumber = this.number - 1;
        if (this.parent.parent) {
          return this.parent.parent.moves[moveNumber - this.parent.parent.start];
        } else {
          return null;
        }
      }
    };

    return Node;

  })();

  PGN.NodeList = (function(_super) {

    __extends(NodeList, _super);

    NodeList.prototype.isNodeList = true;

    function NodeList(game, data) {
      if (data == null) {
        data = null;
      }
      this.nodes = [];
      this.moves = [];
      NodeList.__super__.constructor.call(this, game, data);
    }

    NodeList.prototype.nextVariantMovesStarting = function(number) {
      var move, node, result, _i, _len, _ref1;
      result = [];
      _ref1 = this.nodes;
      for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
        node = _ref1[_i];
        if (node instanceof PGN.NodeList && node.start === number) {
          move = node.moves[0];
          if (move) {
            result.push(move);
          }
        }
      }
      return result;
    };

    NodeList.prototype.insertNode = function(node, pos) {
      var nextNode;
      if (!(node instanceof PGN.Node)) {
        node = this.game.createNode(node.type, node);
      }
      if (node.game !== this.game) {
        throw new Error("Cannot append node because node's game and nodelist's game don't match");
      }
      if (pos > this.nodes.length) {
        pos = this.nodes.length;
      }
      if (node.isMove && pos < this.nodes.length) {
        throw new Error("Moves cannot be inserted, only appended");
      }
      node.parent = this;
      this.nodes.splice(pos, 0, node);
      nextNode = this.nodes[pos + 1];
      if (nextNode && nextNode.isMove) {
        nextNode.showNumber = true;
      }
      if (node.isMove) {
        this.checkMoveNumber(node);
        if (!node.precalculated) {
          this.calculateMove(node);
        }
        this.moves.push(node);
      }
      if (node.isNodeList) {
        node.calculateAllMoves();
      }
      return node;
    };

    NodeList.prototype.appendNode = function(node) {
      return this.insertNode(node, this.nodes.length);
    };

    NodeList.prototype.removeNode = function(node) {
      var moveIndex, nextNode, nodeIndex, prevMove, prevNode, removedNodes, _ref1;
      nodeIndex = this.nodes.indexOf(node);
      if (nodeIndex !== -1) {
        if (node.isMove && (moveIndex = this.moves.indexOf(node)) !== -1) {
          prevMove = node.prevMove();
          if (this.chess != null) {
            this.chess.load(prevMove ? prevMove.fen : this.game.initialFen);
          }
          removedNodes = this.nodes.splice(nodeIndex, this.nodes.length - nodeIndex);
          this.moves.splice(moveIndex, this.moves.length - moveIndex);
          this.expectedNumber = node.number;
          if (!((_ref1 = node.parent) != null ? _ref1.parent : void 0)) {
            node.game.terminator = null;
          }
          return removedNodes;
        } else {
          nextNode = this.nodes[nodeIndex + 1];
          prevNode = this.nodes[nodeIndex - 1];
          if (nextNode && prevNode && nextNode.isMove) {
            nextNode.showNumber = (nextNode.number % 2 === 0) || !prevNode.isMove;
          }
          return this.nodes.splice(nodeIndex, 1);
        }
      }
    };

    NodeList.prototype.isRoot = function() {
      return !(this.parent != null);
    };

    NodeList.prototype.checkMoveNumber = function(move) {
      var _ref1, _ref2, _ref3;
      if ((_ref1 = this.start) == null) {
        this.start = (_ref2 = move.number) != null ? _ref2 : this.defaultFirstMoveNumber;
      }
      if ((_ref3 = this.expectedNumber) == null) {
        this.expectedNumber = this.start;
      }
      if (move.number != null) {
        if (this.expectedNumber !== move.number) {
          throw new Error("Unexpected move number: " + move.number + "; expected: " + this.expectedNumber);
        }
      } else {
        move.number = this.expectedNumber;
      }
      if (move.number % 2 === 1 && this.nodes.length > 1 && (this.nodes[this.nodes.length - 2] instanceof PGN.Move)) {
        move.showNumber = false;
      } else {
        move.showNumber = true;
      }
      return this.expectedNumber += 1;
    };

    NodeList.prototype.calculateMove = function(move) {
      var chMove;
      if (this.chess) {
        chMove = this.chess.move(move.san);
        if (chMove) {
          move.fen = this.chess.fen();
          move.from = chMove.from;
          return move.to = chMove.to;
        } else {
          throw new Error("Invalid move: " + (move.numberText()) + move.san);
        }
      }
    };

    NodeList.prototype.calculateAllMoves = function() {
      var move, moveIndex, node, number, _i, _j, _len, _len1, _ref1, _ref2, _ref3, _results;
      if (!this.fen) {
        number = (_ref1 = this.moves[0]) != null ? _ref1.number : void 0;
        if (number != null) {
          moveIndex = number - 1 - this.parent.start;
          if (moveIndex < -1 || moveIndex >= this.parent.moves.length) {
            throw new Error("Cannot start from move " + number + "; parent moves: " + this.parent.start + ".." + (this.parent.start + this.parent.moves.length));
          }
          this.fen = moveIndex >= 0 ? this.parent.moves[moveIndex].fen : this.parent.fen;
        }
      }
      if (this.fen && !this.chess) {
        this.chess = new Chess(this.fen);
        _ref2 = this.moves;
        for (_i = 0, _len = _ref2.length; _i < _len; _i++) {
          move = _ref2[_i];
          this.calculateMove(move);
        }
        _ref3 = this.nodes;
        _results = [];
        for (_j = 0, _len1 = _ref3.length; _j < _len1; _j++) {
          node = _ref3[_j];
          if (node.isNodeList) {
            _results.push(node.calculateAllMoves());
          } else {
            _results.push(void 0);
          }
        }
        return _results;
      }
    };

    NodeList.prototype.getLastMove = function() {
      var moveIndex;
      if (this.moves.length > 0) {
        return this.moves[this.moves.length - 1];
      } else {
        if (this.parent != null) {
          moveIndex = this.start - 1 - this.parent.start;
          if (moveIndex < 0 || moveIndex > this.parent.moves.length) {
            throw new Error("Cannot start from move " + this.start + "; parent moves: " + this.parent.start + ".." + (this.parent.start + this.parent.moves.length));
          }
          return this.parent.moves[moveIndex];
        }
      }
    };

    NodeList.prototype.update = function(data) {
      var fen, firstTurn, fullmoveNumber, node, _i, _len, _ref1, _results;
      this.defaultFirstMoveNumber = data.defaultFirstMoveNumber;
      if (!this.defaultFirstMoveNumber) {
        if (data != null ? data.fen : void 0) {
          fen = new FEN(data.fen);
          firstTurn = fen.move();
          fullmoveNumber = fen.fullmoveNumber();
          this.defaultFirstMoveNumber = (Number(fullmoveNumber) - 1) * 2 + (firstTurn === 'w' ? 0 : 1);
        } else {
          this.defaultFirstMoveNumber = 0;
        }
      }
      if (data.fen) {
        this.fen = data.fen;
        this.chess = new Chess(data.fen);
      }
      if (data != null ? data.moves : void 0) {
        _ref1 = data.moves;
        _results = [];
        for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
          node = _ref1[_i];
          _results.push(this.appendNode(node));
        }
        return _results;
      }
    };

    NodeList.prototype.toString = function() {
      var node, result;
      result = ((function() {
        var _i, _len, _ref1, _results;
        _ref1 = this.nodes;
        _results = [];
        for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
          node = _ref1[_i];
          _results.push(node.toString());
        }
        return _results;
      }).call(this)).join(" ");
      if (this.parent) {
        result = "(" + result + ")";
      }
      return result;
    };

    NodeList.prototype.serial = function() {
      if (this.isRoot()) {
        return 0;
      } else {
        return this.parent.ravSerial(this);
      }
    };

    NodeList.prototype.ravSerial = function(rav) {
      return this.serial() + this.ravRelativeSerial(rav);
    };

    NodeList.prototype.ravRelativeSerial = function(rav) {
      var i, node, ravIndex, serial, _i;
      ravIndex = this.nodes.indexOf(rav);
      serial = 0;
      for (i = _i = 0; 0 <= ravIndex ? _i < ravIndex : _i > ravIndex; i = 0 <= ravIndex ? ++_i : --_i) {
        node = this.nodes[i];
        if (node.isNodeList) {
          serial += 1 + node.numberOfNodeLists();
        }
      }
      return serial + 1;
    };

    NodeList.prototype.numberOfNodeLists = function() {
      var node, num, _i, _len, _ref1;
      num = 0;
      _ref1 = this.nodes;
      for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
        node = _ref1[_i];
        if (node.isNodeList) {
          num += 1 + node.numberOfNodeLists();
        }
      }
      return num;
    };

    NodeList.prototype.findRavByRelativeSerial = function(serial) {
      var hisRelativeSerial, node, numChildNodeLists, _i, _len, _ref1;
      if (serial === 0) {
        return this;
      }
      hisRelativeSerial = serial;
      _ref1 = this.nodes;
      for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
        node = _ref1[_i];
        if (node.isNodeList) {
          hisRelativeSerial -= 1;
          numChildNodeLists = node.numberOfNodeLists();
          if (hisRelativeSerial <= numChildNodeLists) {
            return node.findRavByRelativeSerial(hisRelativeSerial);
          } else {
            hisRelativeSerial -= numChildNodeLists;
          }
        }
      }
      return null;
    };

    return NodeList;

  })(PGN.Node);

  PGN.Move = (function(_super) {

    __extends(Move, _super);

    function Move() {
      return Move.__super__.constructor.apply(this, arguments);
    }

    Move.prototype.isMove = true;

    Move.prototype.update = function(data) {
      this.number = data.number;
      this.san = this.sanetize(data.san);
      if (data.fen) {
        this.fen = data.fen;
        return this.precalculated = true;
      }
    };

    Move.prototype.sanetize = function(san) {
      if (san === "0-0") {
        return "O-O";
      } else if (san === "0-0-0") {
        return "O-O-O";
      } else {
        return san;
      }
    };

    Move.prototype.numberText = function(forceNumber) {
      if (forceNumber == null) {
        forceNumber = false;
      }
      if ((this.number != null) && (this.showNumber || forceNumber)) {
        return (Math.floor(this.number / 2) + 1).toString() + (this.number % 2 === 0 ? "." : "...");
      } else {
        return "";
      }
    };

    Move.prototype.toString = function(forceNumber) {
      if (forceNumber == null) {
        forceNumber = false;
      }
      return this.numberText(forceNumber) + " " + this.san;
    };

    Move.prototype.ident = function() {
      var result, _ref1;
      result = ((_ref1 = this.number) != null ? _ref1.toString() : void 0) || "";
      if ((this.parent != null) && !this.parent.isRoot()) {
        result += "-" + this.parent.serial();
      }
      return result;
    };

    return Move;

  })(PGN.Node);

  PGN.Commentary = (function(_super) {

    __extends(Commentary, _super);

    function Commentary() {
      return Commentary.__super__.constructor.apply(this, arguments);
    }

    Commentary.prototype.isCommentary = true;

    Commentary.prototype.update = function(data) {
      return this.commentary = data.commentary;
    };

    Commentary.prototype.toString = function() {
      return this.commentary;
    };

    return Commentary;

  })(PGN.Node);

  PGN.NAG = (function(_super) {

    __extends(NAG, _super);

    function NAG() {
      return NAG.__super__.constructor.apply(this, arguments);
    }

    NAG.prototype.isNAG = true;

    NAG.prototype.update = function(data) {
      return this.value = data.value;
    };

    NAG.prototype.toString = function() {
      return this.value;
    };

    return NAG;

  })(PGN.Node);

}).call(this);
