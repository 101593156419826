(function() {
  var __slice = [].slice;

  window.Utils = {
    algebraic: function(i, j) {
      return 'abcdefgh'.substring(i, i + 1) + '12345678'.substring(j, j + 1);
    },
    file: function(str) {
      return str.charCodeAt(0) - 'a'.charCodeAt(0);
    },
    rank: function(str) {
      return parseInt(str[1]) - 1;
    }
  };

  window.pluralizeRules = {
    ru: function(n) {
      if (n % 10 === 1 && n % 100 !== 11) {
        return 0;
      } else if ((n % 10) >= 2 && (n % 10) <= 4 && ((n % 100) < 10 || (n % 100) > 20)) {
        return 1;
      } else {
        return 2;
      }
    },
    en: function(n) {
      if (n === 1) {
        return 0;
      } else {
        return 1;
      }
    }
  };

  window.pluralizeRules['uk'] = window.pluralizeRules['ru'];

  window.pluralize = function() {
    var locale, n, words;
    locale = arguments[0], n = arguments[1], words = 3 <= arguments.length ? __slice.call(arguments, 2) : [];
    return words[window.pluralizeRules[locale](n)];
  };


  ko.observableArray.fn.filterByProperty = function(propName, matchValue) {
    return ko.computed(function() {
      var allItems, current, matchingItems, _i, _len;
      allItems = this();
      matchingItems = [];
      for (_i = 0, _len = allItems.length; _i < _len; _i++) {
        current = allItems[_i];
        if (ko.utils.unwrapObservable(current[propName]) === matchValue) {
          matchingItems.push(current);
        }
      }
      return matchingItems;
    }, this);
  };

  ko.subscribable.fn.prependIfNotNull = function(value) {
    return ko.computed(function() {
      var obs;
      obs = ko.utils.unwrapObservable(value);
      if (obs) {
        return [obs].concat(this());
      } else {
        return this();
      }
    }, this);
  };

  ko.extenders.busyPlayer = function(target, option) {
    var makeBusy, _oldValue;
    makeBusy = function(player, busy) {
      if (player != null) {
        return player.busy(busy);
      }
    };
    _oldValue = target();
    makeBusy(_oldValue);
    target.subscribe(function(newValue) {
      makeBusy(newValue, true);
      makeBusy(_oldValue, false);
      return _oldValue = newValue;
    });
    return target;
  };

  ko.extenders.dirty = function(target, startDirty) {
    var cleanValue, dirtyOverride;
    cleanValue = ko.observable(ko.mapping.toJSON(target));
    dirtyOverride = ko.observable(ko.utils.unwrapObservable(startDirty));
    target.isDirty = ko.computed(function() {
      return dirtyOverride() || ko.mapping.toJSON(target) !== cleanValue();
    });
    target.markClean = function() {
      cleanValue(ko.mapping.toJSON(target));
      return dirtyOverride(false);
    };
    target.markDirty = function() {
      return dirtyOverride(true);
    };
    return target;
  };

  (function() {
    var ignoreNextUpdate, utc;
    ignoreNextUpdate = false;
    utc = function(d) {
      return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0));
    };
    return ko.bindingHandlers.datepicker = {
      init: function(element, valueAccessor, allBindingsAccessor, viewModel) {
        var dp, options;
        options = allBindingsAccessor()['datepickerOptions'];
        $(element).datepicker(options);
        dp = $(element).data('datepicker');
        return $(element).on('changeDate', function() {
          var date, value;
          value = valueAccessor();
          if (ko.isObservable(value)) {
            date = options.parse ? dp.getDate() : dp.getFormattedDate();
            ignoreNextUpdate = true;
            value(date);
            return ignoreNextUpdate = false;
          }
        });
      },
      update: function(element, valueAccessor) {
        var dp, value;
        dp = $(element).data('datepicker');
        value = valueAccessor();
        value = ko.utils.unwrapObservable(value);
        if (value instanceof Date) {
          value = utc(value);
        }
        if (ignoreNextUpdate) {
          return;
        }
        return dp.update(value);
      }
    };
  })();

  (function() {
    return ko.bindingHandlers.tags = {
      init: function(element, valueAccessor, allBindingsAccessor) {
        var tagsList, value;
        tagsList = allBindingsAccessor().tagsList || [];
        $(element).select2({
          tags: tagsList
        });
        value = valueAccessor();
        return $(element).change(function() {
          if (ko.isObservable(value)) {
            return value($(element).select2('val'));
          }
        });
      },
      update: function(element, valueAccessor) {
        return $(element).select2('val', ko.utils.unwrapObservable(valueAccessor()));
      }
    };
  })();

  ko.bindingHandlers.showModal = {
    init: function(element, valueAccessor, allBindingsAccessor, viewModel) {
      return $(element).modal({
        show: false,
        backdrop: 'static'
      });
    },
    update: function(element, valueAccessor, allBindingsAccessor, viewModel) {
      var value;
      value = ko.utils.unwrapObservable(valueAccessor());
      return $(element).modal(value != null ? value : 'show', 'hide');
    }
  };

  ko.bindingHandlers.withProperties = {
    init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
      var innerBindingContext, newProperties;
      newProperties = valueAccessor();
      innerBindingContext = bindingContext.extend(newProperties);
      ko.applyBindingsToDescendants(innerBindingContext, element);
      return {
        controlsDescendantBindings: true
      };
    }
  };

  ko.virtualElements.allowedBindings.withProperties = true;

}).call(this);
