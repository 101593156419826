class PuzzleEditorViewModel
  hintButton: ko.observable(false)
  displayMessage: ko.observable(true)
  displayPgn: ko.observable(false)
  solution: ko.observable()
  constructor: (options) ->
    @board = new ChessBoard
      flip: false
      cellSize: 40
      branchMethod: 'ask'
      rules: 
        fiftyMove: false
        insufficientMaterial: false
        threefoldRepetition: false
    @board.control('w', false)
    @board.control('b', false)
    @board.onreset = =>
      fen = new FEN(@board.fen())
      @fen().placement(fen.placement())
    @visibleView = ko.observable('show')
    @pgnEditorViewModel = new PgnEditorViewModel(@board.pgn, @board.currentPgnMove)

    @saving = ko.observable(false)

    @editable = ko.observable(false)
    @editable.subscribe =>
      @board.control('w', @editable)
      @board.control('b', @editable)

    @puzzle = ko.observable(new Puzzle()).extend(dirty: false)
    
    @fen = ko.observable(new FEN())
    @fenString = ko.computed
      read: =>
        console.log('read fen')
        @fen()?.toString()
      write: (value) =>
        console.log('write fen')
        @fen(new FEN(value))

    (ko.computed =>
      @fen().placement()
    ).subscribe =>
      @board.load(@fen().toString())

    @board.pgn.subscribe =>
      @puzzle().pgn(@board.pgn().toString())

  load: (data) -> 
    if data.pgn && data.pgn.length > 0
      @board.loadPgn(data.pgn)
#     alert(@board.pgn().header['FEN'].split(" ")[1])
      @board.flip(@board.pgn().header['FEN'] && @board.pgn().header['FEN'].split(" ")[1] == 'b')
#     alert(@board.flip())
      data.pgn = @board.pgn().toString()
    ko.mapping.fromJS(data, {}, @puzzle)

    @puzzle.markClean()

  editPgn: ->
    @visibleView('edit-pgn')
  doneEditPgn: ->
    @visibleView('show')
    move = @board.currentPgnMove()
    @board.loadPgn(@puzzle().pgn())
    # stay on the same move if it still exist after changes
    if move
      newMove = @board.pgn().findByIdent(move.ident())
      if newMove && newMove.fen == move.fen  
        @board.goToMoveNode(newMove)
  cancelEditPgn: ->
    @visibleView('show')
    @puzzle().pgn(@board.pgn().toString())



  editPos: ->
    @savedPgn = @board.pgn()
    @savedMove = @board.currentPgnMove()
    @fenString(@savedMove?.fen || @savedPgn.initialFen)

    @board.moveMode('free')
    @board.canThrowOut(true)
    @visibleView('edit-pos')


  cancelEditPos: ->
    @board.pgn(@savedPgn)
    @board.goToMoveNode(@savedMove)

    @board.moveMode('rules')
    @board.canThrowOut(false)
    @visibleView('show')

  doneEditPos: ->
    @board.load(@fen().toString())
    @visibleView('show')
    @board.moveMode('rules')
    @board.canThrowOut(false)

  save: () ->
    puzzle = @puzzle()
    Puzzle.save puzzle,
      beforeSend: =>
        @saving(true)
      success: (json) =>
        puzzle.id(json.id) if puzzle.newRecord()
        @puzzle.markClean()
        @saving(false)
      error: =>
        @saving(false)
        alert "An error occurred on the server"

  pgnMoveClicked: (move) ->
    @board.goToMoveNode(move)

class Puzzle
  @title = (title) ->
    if title then title else "Untitled"
  @save = (puzzle, options=null) ->
    data = ko.mapping.toJS puzzle,
      ignore: ['newRecord', 'id']
    options ||= {}

    data = JSON.stringify({puzzle: data})
    ajaxOptions = {
      url: if puzzle.newRecord() then "/puzzles" else "/puzzles/#{puzzle.id()}"
      type: if puzzle.newRecord() then "POST" else "PUT"
      data: data
      contentType: "application/json"
      dataType: "json"
      processData: false
    }

    $.extend(ajaxOptions, options)
    $.ajax(ajaxOptions)



      
  constructor: ->
    @id = ko.observable()
    @title = ko.observable()
    @bPlayer = ko.observable()
    @bElo = ko.observable()
    @wPlayer = ko.observable()
    @wElo = ko.observable()
    @playedOn = ko.observable()
    @pgn = ko.observable()
    @newRecord = ko.computed =>
      !@id()?

window.PuzzleEditorViewModel = PuzzleEditorViewModel
window.Puzzle = Puzzle