(function() {
  var PgnEditorViewModel,
    __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  PgnEditorViewModel = (function() {

    PgnEditorViewModel.SYMBOLS = {
      "!!": 3,
      "!?": 5,
      "?!": 6,
      "??": 4,
      "!": 1,
      "?": 2,
      "‼": 3,
      "⁇": 4,
      "⁉": 5,
      "⁈": 6,
      "□": 7,
      "+-": 18,
      "-+": 19,
      "=": 10,
      "∞": 13,
      "⩲": 14,
      "⩱": 15,
      "±": 16,
      "∓": 17,
      "⨀": 22,
      "⟳": 32,
      "→": 36,
      "↑": 40,
      "⇆": 132
    };

    PgnEditorViewModel.MOVE_NAGS = [4, 2, 6, 5, 1, 3];

    PgnEditorViewModel.POSITION_NAGS = [18, 16, 14, 10, 15, 17, 19];

    PgnEditorViewModel.NAG_TEXT = {
      ru: {
        1: "очень хороший ход",
        2: "слабый ход",
        3: "отличный ход",
        4: "грубая ошибка",
        5: "ход заслуживающий внимания",
        6: "сомнительный ход",
        10: "равно",
        14: "у белых несколько лучше",
        15: "у чёрных несколько лучше",
        16: "у белых лучше",
        17: "у чёрных лучше",
        18: "у белых решающее преимущество",
        19: "у чёрных решающее преимущество"
      },
      uk: {
        1: "дуже хороший хід",
        2: "слабкий хід",
        3: "відмінний хід",
        4: "груба помилка",
        5: "хід заслуговує на увагу",
        6: "сумнівний хід",
        10: "дорівнює",
        14: "у білих дещо краще",
        15: "у чорних дещо краще",
        16: "у білих краще",
        17: "у чорних краще",
        18: "у білих вирішальна перевага",
        19: "у чорних вирішальна перевага"
      },
      en: {
        1: "very good move",
        2: "mistake",
        3: "excellent  move",
        4: "blunder",
        5: "move deserving attention",
        6: "dubious move",
        10: "equal",
        14: "white is slightly better",
        15: "Black is slightly better",
        16: "white is better",
        17: "black is better",
        18: "white has a decisive advantage",
        19: "black has a decisive advantage"
      }
    };

    PgnEditorViewModel.NAG_SYMBOL = {
      1: "!",
      2: "?",
      3: "!!",
      4: "??",
      5: "!?",
      6: "?!",
      10: "=",
      14: "⩲",
      15: "⩱",
      16: "±",
      17: "∓",
      18: "+-",
      19: "-+"
    };

    function PgnEditorViewModel(pgnObservable, selectedMoveObservable) {
      this.unsetMoveNag = __bind(this.unsetMoveNag, this);

      this.unsetPositionNag = __bind(this.unsetPositionNag, this);

      this.setMoveNag = __bind(this.setMoveNag, this);

      this.setPositionNag = __bind(this.setPositionNag, this);

      this.moveNagChecked = __bind(this.moveNagChecked, this);

      this.positionNagChecked = __bind(this.positionNagChecked, this);

      var _this = this;
      this.pgn = pgnObservable;
      this.selectedMove = selectedMoveObservable;
      this.nags = ko.computed(function() {
        var i, move, nags, node, nodeIndex, nodes, pgn, _i, _ref, _ref1;
        pgn = _this.pgn();
        move = _this.selectedMove();
        if (!move) {
          return [];
        }
        nodes = move.parent.nodes;
        nodeIndex = nodes.indexOf(move);
        nags = [];
        for (i = _i = _ref = nodeIndex + 1, _ref1 = nodes.length; _ref <= _ref1 ? _i < _ref1 : _i > _ref1; i = _ref <= _ref1 ? ++_i : --_i) {
          node = nodes[i];
          if (node.isMove) {
            break;
          }
          if (node.isNAG) {
            nags.push(node);
          }
        }
        return nags;
      });
      this.positionNags = ko.computed(function() {
        return ko.utils.arrayFilter(_this.nags(), function(nag) {
          return PgnEditorViewModel.POSITION_NAGS.indexOf(_this.nagNodeToId(nag)) !== -1;
        });
      });
      this.moveNags = ko.computed(function() {
        return ko.utils.arrayFilter(_this.nags(), function(nag) {
          return PgnEditorViewModel.MOVE_NAGS.indexOf(_this.nagNodeToId(nag)) !== -1;
        });
      });
      this.noPositionNagChecked = ko.computed(function() {
        return !_this.positionNags()[0];
      });
      this.noMoveNagChecked = ko.computed(function() {
        return !_this.moveNags()[0];
      });
      this.upgradeVariantEnabled = ko.computed(function() {
        var _ref, _ref1;
        return (_ref = _this.selectedMove()) != null ? (_ref1 = _ref.parent) != null ? _ref1.parent : void 0 : void 0;
      });
    }

    PgnEditorViewModel.prototype.moveNotSelectedGuard = function() {
      if (!this.selectedMove()) {
        alert("Выберите ход.");
        return true;
      } else {
        return false;
      }
    };

    PgnEditorViewModel.prototype.requireSelectedMove = function(vm, event) {
      return this.moveNotSelectedGuard();
    };

    PgnEditorViewModel.prototype.previewRemoveMove = function() {
      var move, node, nodes, _i, _len, _ref;
      move = this.selectedMove();
      if (!move) {
        return;
      }
      nodes = move.parent.nodes;
      _ref = nodes.slice(nodes.indexOf(move));
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        node = _ref[_i];
        node.willDelete = true;
      }
      return this.pgn.valueHasMutated();
    };

    PgnEditorViewModel.prototype.unpreviewRemoveMove = function() {
      var move, node, nodes, _i, _len, _ref;
      move = this.selectedMove();
      if (!move) {
        return;
      }
      nodes = move.parent.nodes;
      _ref = nodes.slice(nodes.indexOf(move));
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        node = _ref[_i];
        delete node.willDelete;
      }
      return this.pgn.valueHasMutated();
    };

    PgnEditorViewModel.prototype.removeMove = function() {
      var move, nodeList;
      if (this.moveNotSelectedGuard()) {
        return;
      }
      move = this.selectedMove();
      nodeList = move.parent;
      nodeList.removeNode(move);
      if (nodeList.moves.length === 0 && nodeList.parent) {
        nodeList.parent.removeNode(nodeList);
      }
      return this.pgn.valueHasMutated();
    };

    PgnEditorViewModel.prototype.addComment = function() {
      var commentNode, i, index, l, move, newComment, node, nodes, _i;
      if (this.moveNotSelectedGuard()) {
        return;
      }
      move = this.selectedMove();
      nodes = move.parent.nodes;
      index = nodes.indexOf(move);
      l = nodes.length;
      index++;
      for (i = _i = index; index <= l ? _i < l : _i > l; i = index <= l ? ++_i : --_i) {
        node = nodes[i];
        if (node.isCommentary) {
          commentNode = node;
          break;
        } else if (node.isMove) {
          break;
        }
      }
      newComment = prompt("Comment", commentNode != null ? commentNode.commentary : void 0);
      if (newComment) {
        if (!newComment.match(/^\s*\{/)) {
          newComment = "{" + newComment;
        }
        if (!newComment.match(/\}\s*$/)) {
          newComment = newComment + "}";
        }
      }
      if (commentNode) {
        if (newComment) {
          commentNode.commentary = newComment;
        } else {
          commentNode.parent.removeNode(commentNode);
        }
      } else if (newComment) {
        while (nodes[index] && nodes[index].isNag) {
          index++;
        }
        move.parent.insertNode({
          type: 'commentary',
          commentary: newComment
        }, index);
      }
      return this.pgn.valueHasMutated();
    };

    PgnEditorViewModel.prototype.positionNagChecked = function(nagId) {
      var node;
      node = this.positionNags()[0];
      return node && this.nagNodeToId(node) === nagId;
    };

    PgnEditorViewModel.prototype.moveNagChecked = function(nagId) {
      var node;
      node = this.moveNags()[0];
      return node && this.nagNodeToId(node) === nagId;
    };

    PgnEditorViewModel.prototype.setPositionNag = function(nagId) {
      var index, move, nextNode, node;
      if (this.moveNotSelectedGuard()) {
        return;
      }
      move = this.selectedMove();
      node = this.positionNags()[0];
      if (!node) {
        index = move.parent.nodes.indexOf(move) + 1;
        nextNode = move.parent.nodes[index];
        if (nextNode && nextNode.isNAG && PgnEditorViewModel.MOVE_NAGS.indexOf(this.nagNodeToId(nextNode)) !== -1) {
          index += 1;
        }
        node = move.parent.insertNode({
          type: "nag"
        }, index);
      }
      if (this.nagNodeToId(node) === nagId) {
        this.unsetPositionNag();
      } else {
        node.update({
          value: PgnEditorViewModel.NAG_SYMBOL[nagId]
        });
      }
      return this.pgn.valueHasMutated();
    };

    PgnEditorViewModel.prototype.setMoveNag = function(nagId) {
      var index, move, node;
      if (this.moveNotSelectedGuard()) {
        return;
      }
      move = this.selectedMove();
      node = this.moveNags()[0];
      if (!node) {
        index = move.parent.nodes.indexOf(move) + 1;
        node = move.parent.insertNode({
          type: "nag"
        }, index);
      }
      if (this.nagNodeToId(node) === nagId) {
        this.unsetMoveNag();
      } else {
        node.update({
          value: PgnEditorViewModel.NAG_SYMBOL[nagId]
        });
      }
      return this.pgn.valueHasMutated();
    };

    PgnEditorViewModel.prototype.unsetPositionNag = function() {
      var node;
      node = this.positionNags()[0];
      if (node) {
        node.parent.removeNode(node);
        return this.pgn.valueHasMutated();
      }
    };

    PgnEditorViewModel.prototype.unsetMoveNag = function() {
      var node;
      node = this.moveNags()[0];
      if (node) {
        node.parent.removeNode(node);
        return this.pgn.valueHasMutated();
      }
    };

    PgnEditorViewModel.prototype.nagNodeToId = function(node) {
      var match;
      if (!(node.isNAG && node.value)) {
        return null;
      }
      if (match = node.value.match(/$\$([0-9]{1,3})/)) {
        return Number(match[1]);
      } else {
        return PgnEditorViewModel.SYMBOLS[node.value] || 0;
      }
    };

    PgnEditorViewModel.prototype.nagNodesToIds = function(nodes) {
      var _this = this;
      return ko.utils.arrayMap(nodes, function(nag) {
        return _this.nagNodeToId(nag);
      });
    };

    PgnEditorViewModel.prototype.upgradeVariant = function() {
      var altMove, keepVariants, move, newVariant, node, nodeIndex, parentVariant, tail, variant, variantNode, _i, _j, _k, _l, _len, _len1, _len2, _len3, _len4, _len5, _len6, _len7, _m, _n, _o, _p, _ref, _ref1, _ref2, _ref3, _ref4, _ref5, _ref6;
      if (this.moveNotSelectedGuard()) {
        return;
      }
      move = this.selectedMove();
      if (!((_ref = move.parent) != null ? _ref.parent : void 0)) {
        return;
      }
      variant = move.parent;
      parentVariant = variant.parent;
      altMove = null;
      _ref1 = parentVariant.moves;
      for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
        move = _ref1[_i];
        if (move.number === variant.start) {
          altMove = move;
          break;
        }
      }
      parentVariant.removeNode(variant);
      if (altMove) {
        keepVariants = [];
        nodeIndex = parentVariant.nodes.indexOf(altMove);
        _ref2 = parentVariant.nodes.slice(nodeIndex);
        for (_j = 0, _len1 = _ref2.length; _j < _len1; _j++) {
          node = _ref2[_j];
          if (node.isNodeList && node.start <= altMove.number) {
            keepVariants.push(node);
          }
        }
        tail = parentVariant.removeNode(altMove);
        for (_k = 0, _len2 = keepVariants.length; _k < _len2; _k++) {
          node = keepVariants[_k];
          if ((nodeIndex = tail.indexOf(node)) !== -1) {
            tail.splice(nodeIndex, 1);
          }
        }
      }
      _ref3 = variant.nodes;
      for (_l = 0, _len3 = _ref3.length; _l < _len3; _l++) {
        node = _ref3[_l];
        parentVariant.appendNode(node);
      }
      _ref4 = keepVariants.reverse();
      for (_m = 0, _len4 = _ref4.length; _m < _len4; _m++) {
        variantNode = _ref4[_m];
        nodeIndex = null;
        _ref5 = parentVariant.moves;
        for (_n = 0, _len5 = _ref5.length; _n < _len5; _n++) {
          move = _ref5[_n];
          if (move.number === variantNode.start) {
            nodeIndex = parentVariant.nodes.indexOf(move);
            parentVariant.insertNode(variantNode, nodeIndex + 1);
            break;
          }
        }
      }
      if (tail.length && tail.length > 0) {
        _ref6 = parentVariant.moves;
        for (_o = 0, _len6 = _ref6.length; _o < _len6; _o++) {
          move = _ref6[_o];
          if (move.number === variant.start) {
            newVariant = parentVariant.insertNode({
              type: "nodelist",
              fen: variant.fen
            }, parentVariant.nodes.indexOf(move) + 1);
            for (_p = 0, _len7 = tail.length; _p < _len7; _p++) {
              node = tail[_p];
              newVariant.appendNode(node);
            }
            break;
          }
        }
      }
      return this.pgn.valueHasMutated();
    };

    return PgnEditorViewModel;

  })();

  window.PgnEditorViewModel = PgnEditorViewModel;

}).call(this);
