/*
(function() {
  var FEN;
*/
  FEN = (function() {

    FEN.DEFAULT_FEN = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";

    FEN.EMPTY_FEN = "";

    function FEN(fen) {
      var _this = this;
      this.placement = ko.observable();
      this.move = ko.observable();
      this.enPasant = ko.observable();
      this.halfmoveClock = ko.observable();
      this.fullmoveNumber = ko.observable();
      this.wkCastling = ko.observable(true);
      this.wqCastling = ko.observable(true);
      this.bkCastling = ko.observable(true);
      this.bqCastling = ko.observable(true);
      this.castlings = ko.computed({
        read: function() {
          var result;
          result = "";
          if (_this.wkCastling()) {
            result += "K";
          }
          if (_this.wqCastling()) {
            result += "Q";
          }
          if (_this.bkCastling()) {
            result += "k";
          }
          if (_this.bqCastling()) {
            result += "q";
          }
          if (result.length === 0) {
            result += "-";
          }
          return result;
        },
        write: function(value) {
          _this.wkCastling(value && (value.indexOf('K') !== -1));
          _this.wqCastling(value && (value.indexOf('Q') !== -1));
          _this.bkCastling(value && (value.indexOf('k') !== -1));
          return _this.bqCastling(value && (value.indexOf('q') !== -1));
        }
      });
      this.toString = ko.computed(function() {
        return [_this.placement(), _this.move(), _this.castlings(), _this.enPasant(), _this.halfmoveClock(), _this.fullmoveNumber()].join(" ");
      });
      this.load(fen || FEN.DEFAULT_FEN);
    }

    FEN.prototype.load = function(fen) {
      var values;
      values = fen.split(" ");
      this.placement(values[0]);
      this.move(values[1]);
      this.castlings(values[2]);
      this.enPasant(values[3]);
      this.halfmoveClock(values[4]);
      return this.fullmoveNumber(values[5]);
    };

    FEN.prototype.clearPlacement = function() {
      return this.placement("8/8/8/8/8/8/8/8");
    };

    FEN.prototype.reset = function() {
      return this.load(FEN.DEFAULT_FEN);
    };

    return FEN;

  })();
/*
  window.FEN = FEN;

}).call(this);
*/
