
ko.bindingHandlers.chessPos = {
  init: function(){},
  update: function(element, valueAccessor, allBindingsAccessor, viewModel){
    var posObservable = valueAccessor();
    var pos = posObservable();
    if(pos) {
      var i = Utils.file(pos), j = Utils.rank(pos);
      var l = viewModel.board().filePos(i),
          t = viewModel.board().rankPos(j);
      $(element).css({"left": l, "top": t});
    }
  }
}

ko.bindingHandlers.moveablePiece = {
  init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext){
    $(element).draggable({
      revert: "invalid", 
      start: function(){
      // удалить все
          $('.piece').each(function(index) {
          $(this).removeClass('e-clicked');
          });
       // повесить      
         $(element).addClass('e-clicked');

        viewModel.activate();
      },
      stop: function(event, ui) {
      }
    });
    $(element).mousedown(function(){
    	  var mmm = ko.utils.unwrapObservable(valueAccessor());
    	  if (mmm){
    	  	
        viewModel.activate();
        // удалить все
          $('.piece').each(function(index) {
          $(this).removeClass('e-clicked');
          });
       // повесить 
         $(element).addClass('e-clicked');
          }else {
                
        }
      });

    $(element).css({"position": "absolute"});
  },
  update: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var moveable = ko.utils.unwrapObservable(valueAccessor());
    $(element).draggable(moveable ? "enable" : "disable");
  }
}

ko.bindingHandlers.acceptPiece = {
  init: function(element, valueAccessor, allBindingsAccessor, viewModel){
    $(element).droppable({accept: "", greedy: true, drop: function(event, ui){
      viewModel.drop(ko.dataFor(ui.draggable[0]));
//      if(window.puzzle && viewModel.board().pgn().body.moves.length > 0){alert(viewModel.board().pgn().body.moves[0].san +
//       viewModel.board().pgn().body.moves[viewModel.board().currentPgnMove().number].san);}; 
//      if(window.puzzle && viewModel.board().pgn().body.moves.length > 0){alert(viewModel.board().currentPgnMove().san +
//       viewModel.board().pgn().body.moves[viewModel.board().currentPgnMove().number-(viewModel.board().flip() ? 1 : 0)].san);}; 

//      if(window.puzzle){     	
//     	alert(viewModel.board().currentPgnMove().number+' '+viewModel.board().currentPgnMove().san+' '+
//     	 viewModel.board().pgn().body.moves[viewModel.board().currentPgnMove().number].san);}
//     	alert(viewModel.board().currentPgnMove().san);
    ///////  if (!!window.puzzle) {
      ui.draggable.draggable('option', 'revert', false);////////} // do not revert if dropped on active cell
    }});
    $(element).mousedown(function(){
    	if ($('.e-clicked').length > 0 && $(element).hasClass("active")) {
    	var eclicked = $('.e-clicked');
//    	alert(viewModel.board().pgn().body.moves.length);
//      alert(viewModel.board().pgn().body.fen);
//    if(window.puzzle && viewModel.board().pgn().body.moves.length > 0){alert(viewModel.board().pgn().body.moves[0].san);};   
//      alert(viewModel.board().pgn().body.moves[0].from);
    	viewModel.drop(ko.dataFor(eclicked[0]));
    }
    });	
  },
  update: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    var accept = ko.utils.unwrapObservable(valueAccessor());
    $(element).droppable("option", "accept", accept ? ".piece" : "");
  }
}


ko.bindingHandlers.chessBoard = {
  init: function(element, valueAccessor, allBindingsAccessor, viewModel) {
    $(element).droppable({accept: ".piece", greedy: true, drop: function(event, ui){
    	ui.draggable.draggable("option", "revertDuration", 0 );
    	ui.draggable.draggable('option', 'revert', 'valid'); //revert if dropped on board
    }});
    $(document.body).droppable({accept: ".piece", drop: function(event, ui){
    	// Piece DROPPED OUT
      var revert = viewModel.droppedOut(ko.dataFor(ui.draggable[0]))
      ui.draggable.draggable("option", "revertDuration", 0 );
      ui.draggable.draggable('option', 'revert', revert); // revert if can't throw out pieces
      
    }});
  },
  update: function(element, valueAccessor, allBindingsAccessor, viewModel) {
  }
}